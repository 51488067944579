























































import Vue from "vue";
import SectionContainer from "@/components/SectionContainer.vue";
import FeatureCard from "@/components/FeatureCard.vue";

import { validationMixin } from "vuelidate";
import { required, email, minLength } from "vuelidate/lib/validators";

import { mdiCheckCircle, mdiAlertOctagon } from '@mdi/js'

export default Vue.extend({
  name: "JoinWaitingList",

  mixins: [validationMixin],

  components: {
    FeatureCard,
    SectionContainer,
  },

  data: () => ({
    mdiCheckCircle,
    mdiAlertOctagon,
    email: "",
    details: "",
    submitted: false,
    loading: false,
    error: "",
  }),

  validations: {
    email: {
      required,
      email,
    },
    details: {
      required,
      minLength: minLength(4)
    }
  },

  computed: {
    emailErrors() {
      const errors: string[] = [];
      if (!this.$v.email.$dirty) return errors;
      if (!this.$v.email.email) errors.push("Email format incorrect");
      if (!this.$v.email.required) errors.push("Email is required.");
      return errors;
    },
    detailsErrors() {
      const errors: string[] = [];
      if (!this.$v.details.$dirty) return errors;
      if (!this.$v.details.required || !this.$v.details.minLength) errors.push("Details are required.");
      return errors;
    },
    valid() {
      return !this.$v.$invalid;
    }
  },

  methods: {
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.loading = true;
        fetch("/join_waitinglist", {
          method: "POST",
          mode: "cors",
          headers: {"Content-Type": "application/json"},
          body: JSON.stringify({
            email: this.email,
            details: this.details,
          })
        }).then((response)=> {
          if (response.ok) {
            this.submitted = true;
          } else {
            console.error(response);
            response.text().then(text => {
              this.error = `${response.statusText || text} (${response.status})`
            })
          }
        }).catch((error) => {
          console.error(error);
        }).finally(() => {
          this.loading = false;
        })
      }
    },
  },

  metaInfo: {
    title: 'Join the waiting list',
  }
});
